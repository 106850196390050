// 1. 引入
import axios from "axios";
import {ElMessage} from 'element-plus'
import router from "@/router";
import appConfig from '@/app.config.json'

// axios.defaults.withCredentials = true

// 2. 创建实例
const instance = axios.create({
    baseURL: appConfig.apiUrl,
    withCredentials: true,
    timeout: 10000
})

instance.interceptors.response.use(
    response => {
        return response;
    }, function (error) {

        if (error.response && error.response.status === 401) {
            router.replace({
                path: '/login'
            });
        } else {
            ElMessage({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }

        return Promise.reject(error)   // 返回接口返回的错误信息
    }
);

// 4. 导出
export default instance
