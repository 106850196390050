import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import unicons from 'vue-unicons'
import "bootstrap/dist/js/bootstrap.js";
import vant from 'vant'
import elementplus from 'element-plus'
import 'element-plus/dist/index.css'
import 'vant/lib/index.css';
import axios from "./axios"
import store from './store';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import MasonryWall from '@yeger/vue-masonry-wall'

const app = createApp(App)


app.use(router)
app.use(unicons)
app.use(vant)
app.use(store)
app.use(elementplus, {locale: zhCn})
app.use(MasonryWall)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.provide('$axios', axios)
app.provide('$router', router)

app.mount('#app')
const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
    }
}