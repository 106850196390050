<template>
  <el-watermark :font="font" :content="[store.state.userinfo.nickname]" :gap="[100,100]">
    <router-view></router-view>
  </el-watermark>
</template>

<script setup>
import {reactive} from "vue";
import store from "@/store";

const font = reactive({
  color: 'rgba(0, 0, 0, .15)',
  fontSize: 20
})


</script>

<style>
@import '/src/design/index.css';
</style>

